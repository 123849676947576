var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodityBtn" },
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "life-goods-form",
              attrs: {
                inline: true,
                model: _vm.searchInfo,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品来源:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择商品来源" },
                      model: {
                        value: _vm.searchInfo.sc_type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "sc_type", $$v)
                        },
                        expression: "searchInfo.sc_type"
                      }
                    },
                    _vm._l(_vm.categories, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.city_id,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "city_id", $$v)
                        },
                        expression: "searchInfo.city_id"
                      }
                    },
                    _vm._l(_vm.cityList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请输入名称",
                        clearable: "",
                        remote: "",
                        "remote-method": _vm.getStoreList
                      },
                      on: { clear: _vm.getStoreList },
                      model: {
                        value: _vm.searchInfo.shop_name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "shop_name", $$v)
                        },
                        expression: "searchInfo.shop_name"
                      }
                    },
                    _vm._l(_vm.storeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.name }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库存:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.searchInfo.stock_status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "stock_status", $$v)
                        },
                        expression: "searchInfo.stock_status"
                      }
                    },
                    _vm._l(_vm.stockOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { filterable: "", placeholder: "请选择商品状态" },
                      model: {
                        value: _vm.searchInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "status", $$v)
                        },
                        expression: "searchInfo.status"
                      }
                    },
                    _vm._l(_vm.statusOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品ID:" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入商品ID",
                      onkeyup: "value=value.replace(/[^\\d]/g,'')"
                    },
                    model: {
                      value: _vm.searchInfo.goods_id,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "goods_id", _vm._n($$v))
                      },
                      expression: "searchInfo.goods_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品标题:" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品标题" },
                    model: {
                      value: _vm.searchInfo.goods_title,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "goods_title", $$v)
                      },
                      expression: "searchInfo.goods_title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖时间:" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { "margin-top": "6px", width: "100%" },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    model: {
                      value: _vm.saleTime,
                      callback: function($$v) {
                        _vm.saleTime = $$v
                      },
                      expression: "saleTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { display: "block" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  ),
                  _c("el-button", { on: { click: _vm.onReset } }, [
                    _vm._v("重置")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, stripe: "", "tooltip-effect": "dark" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品ID", prop: "id", width: "80px" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品标题", prop: "name", width: "140px" }
          }),
          _c("el-table-column", {
            attrs: { label: "商品图片", prop: "cover", width: "120px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: scope.row.cover, fit: "contain" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "80px", label: "商品来源", prop: "sc_type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.sc_type === 23
                      ? _c("span", [_vm._v("口碑")])
                      : _vm._e(),
                    scope.row.sc_type === 24
                      ? _c("span", [_vm._v("联联")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "110px", label: "适用门店数量", prop: "shop_count" }
          }),
          _c("el-table-column", {
            attrs: { width: "90px", label: "原价", prop: "original_price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatPrice")(scope.row.original_price)
                          )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "90px", label: "售价" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatPrice")(scope.row.sale_price))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "90px", label: "库存", prop: "stock" }
          }),
          _c("el-table-column", {
            attrs: { "min-width": "190px", label: "售卖时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("p", [
                      _vm._v(
                        "开始时间：" +
                          _vm._s(_vm._f("handleTime")(scope.row.begin_time))
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "结束时间：" +
                          _vm._s(_vm._f("handleTime")(scope.row.end_time))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "80px", label: "当前状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" }
                          },
                          [_vm._v("已上架")]
                        )
                      : scope.row.status === 3
                      ? _c(
                          "el-button",
                          { attrs: { size: "mini", type: "info", plain: "" } },
                          [_vm._v("已下架")]
                        )
                      : _c(
                          "el-button",
                          { attrs: { size: "mini", type: "info", plain: "" } },
                          [_vm._v("未知")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.setEquityGoods(scope.row, 3)
                              }
                            }
                          },
                          [_vm._v(" 下架")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.setEquityGoods(scope.row, 2)
                              }
                            }
                          },
                          [_vm._v("上架")]
                        ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.updateLifeGoods(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("查看详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }